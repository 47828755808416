import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import NonbmaWave2 from "src/components/nonbmaWave2"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"


const wavelpData2 = import("./data/data.json");

export default function prdbest() {

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`

body {
	font-family: 'Roboto', sans-serif;
}
       .footerlinks-module--br {
         display: none!important;
       }

       .btf_cb-module--btfCb button.btf_cb-module--install {
         background: #39b54a;
         padding: 20px;
         font-size: 30px;
         border-radius: 32px;
         box-shadow: 0 10px 20px -10px #23e332;
         margin-bottom: 25px;
       }

       #ctadisclosuresearch-module--ctadisclaimersearch {
         width: 80%;
         margin: 0 auto;
       }

       .wave-module--wave button.wave-module--waveBtn{
       	animation: 1.5s normal 0.5s 1;
        animation-name: slideInFromLeft;
       	animation-fill-mode: forwards;

       }
       @keyframes slideInFromLeft {
       	from {
       		background-position: right bottom;
       	}
       	to {
       		background-position: 0 0;
       		background-color: #096bfb;
       	}
       }

       .wave-module--wave h1 {
        width:100%;
        font-weight:600;
        font-size:25px;
       }

       .wave-module--wave ul.wave-module--bullets {
        flex-direction: row;
        width: 594px;
        justify-content: center;
      }
      .wave-module--wave ul.wave-module--bullets li:nth-child(1), .wave-module--wave ul.wave-module--bullets li:nth-child(2) {
        flex: 0 1 100%;
      }



    `}
    </style>
    <style type="text/css">
         </style>
    <title>View any web page easily - Easy View</title></Helmet>
    <section>
      <NonbmaWave2 data={wavelpData2}></NonbmaWave2>
      </section>
    </HomepageLayout>
  )
}
